import './App.css';
import Main from './components/Main';


function App() {
  return (
    <div className="App" style={{  backgroundColor: 'black' }}>
       <Main />
    </div>
  );
}

export default App;
