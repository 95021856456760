import React from 'react'
import './Main.css'
import { Container, Row, Col } from 'react-bootstrap';
// IMG
import mainImage from '../assets/img/main15.jpg'
import happyCustomer from '../assets/img/happyCustomer.jpeg';
import happyDriver from '../assets/img/happyDriver.jpeg';
import oldMan from '../assets/img/oldMan.jpeg';
import rocket from '../assets/img/racket.png'
import appBig from '../assets/img/appBig.jpg'
import abcnetLogo from '../assets/img/abcnet.png'
import play from '../assets/img/play.png'



function Main() {



  return (
    <div>
        <div className='center'>
             <h1 style={{color:'white'}}>Aplikace pro Taxi služby a smluvní přepravy osob</h1>
        </div>
       <div className='mainLogoContainer'>
            <img alt="main logo"  src={mainImage} className='mainLogo'/>
       </div>
        

        <div className='textContent'>
            <h2>Proč přejít na objednávkový systém a aplikaci od firmy  ABCNET?</h2>
            <p><span style={{color: 'rgb(51,154,222)'}}>Modernizace podnikání: </span>Přechod na digitální platformu ukazuje, že vaše taxislužba je moderní a konkurenceschopná. To může přilákat <span style={{color: 'rgb(51,154,222)'}}>nové zákazníky</span> a přispět k dlouhodobému úspěchu podniku.</p>
            <br></br>
            <p><span style={{color: 'rgb(51,154,222)'}}>Spousta ušetřeného času.</span> Díky automatizaci objednávky a plánování šichet strávíte méně času u papírování a více s rodinou či na cestách k dalším zákazníkům</p>
        </div>

        <Container>
            <Row className="gx-3">
                <Col xs={12} md={4}>
                    <div className='oneWhy'>
                        <img src={oldMan} alt="Main 3" className="img-fluid" />
                        <h2>Více zakázek</h2>
                        <p>✔ Vyrovnejte se konkurenci a přejděte do 21. století.</p>
                    </div>
                </Col>
                <Col xs={12} md={4} >
                  <div className='oneWhy'>
                      <img src={happyCustomer} alt="Main 1" className="img-fluid" />
                      <h2>Spokojenější klientela</h2>
                      <p>✔ Získání více zakázek díky oslovení mladší klienteli</p>
                      <p>✔ S naší revoluční appkou to bude hračka</p>
                      
                  </div>
                </Col>
                <Col xs={12} md={4} >
                    <div className='oneWhy'>
                          <img src={happyDriver} alt="Main 2" className="img-fluid" />
                          <h2>Řidiči mají o všem přehled</h2>
                          <p>✔ Aplikace ušitá řidičům taxi služby na míru jejich přáním</p>
                          <p>✔ Jízdy bez taxametru</p>
                    </div>
                </Col>
            </Row>
      </Container>
   

      <Container>
          <div className='textContent'>
              <h2>Jak přechod na aplikaci probíhá?<img alt="videoImg"  src={rocket} style={{width: 50}}/></h2>
          </div>
      </Container>
    
      
      
      <Container>
          <Row className="gx-3">
            
                    <Col xs={12} md={3} className='oneHowWillBee'>
                        <div className='oneNumber'>
                            <h2>1</h2>
                        </div>
                        <h2>Mrknete na video</h2>
                    </Col>
       

                <Col xs={12} md={3} className='oneHowWillBee'>
                    <div className='oneNumber'>
                        <h2>2</h2>
                    </div>
                    <h2>Zavolejte nebo mailujte.</h2>
                </Col>

                <Col xs={12} md={3} className='oneHowWillBee'>
                    <div className='oneNumber'>
                        <h2>3</h2>
                    </div>
                    <h2>Sestavíme plán aplikace dle Vašich přání</h2>
                </Col>

                <Col xs={12} md={3} className='oneHowWillBee'>
                    <div className='oneNumber'>
                        <h2>4</h2>
                    </div>
                    <h2>Po podepsání smlouvy Vám připravíme aplikaci s Vašimi požadavky</h2>
                </Col>
          </Row>
      </Container>


      <Container style={{marginTop: 100, borderRadius: 25}}> 
            <a href="https://www.youtube.com/watch?v=57viUPRt0g0" target="_blank" style={{textDecoration: 'none'}}>
                <div className='buttonPlay' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, border:'1px solid #a3a3a3', borderRadius: 20, padding: 10, cursor: 'pointer', background: 'rgb(15,5,23'}}>
                        <h2>Ukázka naší aplikace</h2>
                        <img alt="" src={play} style={{width: 100, borderRadius:25}}/>
                </div>
            </a>
      </Container>


      <Container style={{marginTop: 100, padding: 30, background: 'rgb(6,19,27)', borderRadius: 25}}> 
            <Row>
                <Col xs={12} md={6} >
                    <div className='textContentLeft'>
                        <h2>Maximalizujte své příjmy s naší aplikací pro řidiče</h2>
                        <p >Hledáte nové možnosti pro vydělávání? Připojte se k naší aplikaci pro řidiče a získejte přístup k širokému spektru cestujících, což vám umožní maximalizovat své příjmy.</p>
                        <p>Naše aplikace vám otevírá dveře ke širokému trhu cestujících. Získáte přístup k mnoha potenciálním zákazníkům, což vám umožní zvýšit své příjmy a rozšířit své podnikání.</p>
                    </div>
                   
                </Col>
                <Col xs={12} md={6}>
                  <img alt="" src={appBig} style={{width: '100%', borderRadius:25}}/>
                </Col>
            </Row>
      </Container>



      <Container style={{marginTop: 200, borderRadius: 25}}> 
           
                <div className='buttonPlay' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, border:'1px solid #a3a3a3', borderRadius: 20, padding: 10, cursor: 'pointer', background: 'rgb(15,5,23'}}>
                        <h2>Cena startuje od 900 Kč / měsíc</h2>
              
                </div>
         
      </Container>


      <div style={{marginTop: 200, padding: 30, background: 'rgb(6,19,27)'}}> 
            <Row>
                <Col xs={12} md={4} style={{ alignItems: 'center', display: 'flex'}}>
                    <img alt="" src={abcnetLogo} style={{width: '60%'}}/>
                </Col>
                <Col xs={12} md={8} >
                    <div className='textContentRight'>
                        <h2 style={{color: 'rgb(241,168,97)'}}>Neváhejte nás kontaktovat, jsme tu pro Vás</h2>
                        <p style={{color: 'rgb(253, 242, 171)', fontSize: 18, margin: 0}}>Abcnet s.r.o.  </p>
                        <p style={{color: 'rgb(253, 242, 171)', fontSize: 18, margin: 0}}>Ič: 05522005</p>
                        <p style={{color: 'rgb(253, 242, 171)', fontSize: 18, margin: 0}}>Tel: 602 448 912</p>
                        <p style={{color: 'rgb(253, 242, 171)', fontSize: 18, margin: 0}}>Email: info@abcnet.cz</p>
                    </div>
                </Col>
           
            </Row>
      </div>

    </div>
  )
}

export default Main
